import androidx.compose.runtime.CompositionLocalProvider
import com.varabyte.kobweb.core.AppGlobals
import com.varabyte.kobweb.navigation.RoutePrefix
import com.varabyte.kobweb.navigation.Router
import com.varabyte.kobweb.navigation.UpdateHistoryMode
import com.varabyte.kobweb.silk.defer.renderWithDeferred
import kotlinx.browser.document
import kotlinx.browser.window
import org.jetbrains.compose.web.renderComposable

public fun main() {
    RoutePrefix.set("")
    val router = Router()
    com.varabyte.kobweb.core.init.initKobweb(router) { ctx ->
        ctx.router.register("/") { dev.stralman.pages.HomePage() }
        ctx.router.register("/about") { dev.stralman.pages.AboutPage() }
        ctx.router.register("/misc/woodworking") { dev.stralman.pages.misc.WoodworkingPage() }
        ctx.router.register("/posts/hacking_my_lg_tv_again/") {
                dev.stralman.pages.posts.hacking_my_lg_tv_again.IndexPage() }
        ctx.router.register("/posts/kicad/dxf_edge_cut_and_zone") {
                dev.stralman.pages.posts.kicad.Dxf_edge_cut_and_zonePage() }
        ctx.router.register("/posts/mount_with_rclone") {
                dev.stralman.pages.posts.Mount_with_rclonePage() }
        ctx.router.register("/posts/om_10_repair/") {
                dev.stralman.pages.posts.om_10_repair.IndexPage() }
        ctx.router.register("/posts/prints/rip_n_print/") {
                dev.stralman.pages.posts.prints.rip_n_print.IndexPage() }
        ctx.router.register("/posts/protohackers/problem_0") {
                dev.stralman.pages.posts.protohackers.Problem_0Page() }
        ctx.router.register("/posts/protohackers/problem_1") {
                dev.stralman.pages.posts.protohackers.Problem_1Page() }
        ctx.router.register("/posts/smart_mirror/") {
                dev.stralman.pages.posts.smart_mirror.IndexPage() }

    }
    router.setLegacyRouteRedirectStrategy(Router.LegacyRouteRedirectStrategy.DISALLOW)
    router.addRouteInterceptor {
        path = path.removeSuffix(".html").removeSuffix(".htm")
    }

    com.varabyte.kobweb.silk.init.additionalSilkInitialization = { ctx ->
        com.varabyte.kobweb.silk.init.initSilkWidgets(ctx)
        com.varabyte.kobweb.silk.init.initSilkWidgetsKobweb(ctx)
        ctx.theme.registerComponentStyle(dev.stralman.components.layouts.BadgeText)
        ctx.theme.registerComponentStyle(dev.stralman.components.layouts.TocStyle)
        ctx.theme.registerComponentStyle(dev.stralman.components.layouts.MarkdownStyle)
        ctx.theme.registerComponentStyle(dev.stralman.components.layouts.PageContentStyle)
        ctx.theme.registerComponentStyle(dev.stralman.components.sections.FooterStyle)
        ctx.theme.registerComponentStyle(dev.stralman.components.sections.CopyrightStyle)
        ctx.theme.registerComponentStyle(dev.stralman.components.sections.PSecondaryStyle)
        ctx.theme.registerComponentStyle(dev.stralman.components.sections.FaIconStyle)
        ctx.theme.registerComponentStyle(dev.stralman.components.widgets.badge.BadgeP)
        ctx.theme.registerComponentStyle(dev.stralman.components.widgets.code.CodeBlockStyle)
        ctx.theme.registerComponentStyle(dev.stralman.components.widgets.code.InlineCodeStyle)
        ctx.theme.registerComponentStyle(dev.stralman.components.widgets.image.RoundBorderImageStyle)
        ctx.theme.registerComponentStyle(dev.stralman.components.widgets.navigation.HoverLinkStyle)
        ctx.theme.registerComponentStyle(dev.stralman.pages.BlogPostEntry)
        ctx.theme.registerComponentStyle(dev.stralman.pages.BlogPostRow)
        dev.stralman.initSiteStyles(ctx)
        dev.stralman.components.layouts.initHighlightJs(ctx)
        dev.stralman.components.widgets.navigation.initHeaderLinkInteraction(ctx)
    }

    // For SEO, we may bake the contents of a page in at build time. However, we will
    // overwrite them the first time we render this page with their composable, dynamic
    // versions. Think of this as poor man's hydration :)
    // See also: https://en.wikipedia.org/wiki/Hydration_(web_development)
    router.navigateTo(window.location.href.removePrefix(window.location.origin),
            UpdateHistoryMode.REPLACE)

    val root = document.getElementById("root")!!
    while (root.firstChild != null) { root.removeChild(root.firstChild!!) }

    AppGlobals.initialize(mapOf("title" to "Karl Strålman"))
    renderComposable(rootElementId = "root") {
        dev.stralman.AppEntry {
            router.renderActivePage { renderWithDeferred { it() } }
        }
    }
}
