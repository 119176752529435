// This file is generated. Modify the build script if you need to change it.

package dev.stralman.blogposts

import kotlinx.datetime.toLocalDate
import kotlinx.datetime.LocalDate

data class BlogPost(
   val route: String,
   val title: String,
   val author: String,
   val date: LocalDate,
   val tags: List<String> = emptyList()
)

val markdownResourceDir = "site/src/jsMain/resources/markdown/posts"

val blogPostEntries = listOf(
   BlogPost(
   route = "posts/prints/rip_n_print",
   date = "2024-08-07".toLocalDate(),
   title = "Rip Guild Wars 2 character and 3D print",
   author = "Karl Strålman",
   tags = listOf<String>("prints", "gw2")
),
   BlogPost(
   route = "posts/kicad/via_stitching_edge_pcb",
   date = "2024-06-24".toLocalDate(),
   title = "KiCad: Edge cut and copper zone from CAD sketch",
   author = "Karl Strålman",
   tags = listOf<String>("kicad")
),
   BlogPost(
   route = "posts/kicad/dxf_edge_cut_and_zone",
   date = "2024-06-24".toLocalDate(),
   title = "KiCad: Edge cut and copper zone from CAD sketch",
   author = "Karl Strålman",
   tags = listOf<String>("kicad")
),
   BlogPost(
   route = "posts/hacking_my_lg_tv_again",
   date = "2023-05-27".toLocalDate(),
   title = "Hacking my LG webOS TV (again)",
   author = "Karl Strålman",
   tags = listOf<String>("webosbrew", "hacking")
),
   BlogPost(
   route = "posts/smart_mirror",
   date = "2023-01-22".toLocalDate(),
   title = "Yet another smart mirror",
   author = "Karl Strålman",
   tags = listOf<String>("homeassistant")
),
   BlogPost(
   route = "posts/mount_with_rclone",
   date = "2023-01-07".toLocalDate(),
   title = "Mount your cloud storage with rclone",
   author = "Karl Strålman",
   tags = listOf<String>("tools")
),
   BlogPost(
   route = "posts/protohackers/problem_1",
   date = "2022-10-09".toLocalDate(),
   title = "Protohackers - 1: Prime Time",
   author = "Karl Strålman",
   tags = listOf<String>("dev_challenges")
),
   BlogPost(
   route = "posts/protohackers/problem_0",
   date = "2022-09-26".toLocalDate(),
   title = "Protohackers - 0: Smoke Test",
   author = "Karl Strålman",
   tags = listOf<String>("dev_challenges")
),
   BlogPost(
   route = "posts/om_10_repair",
   date = "2022-08-23".toLocalDate(),
   title = "Analog camera despair: OM-10 needs repair",
   author = "Karl Strålman",
   tags = listOf<String>("analog_cameras")
),
)
